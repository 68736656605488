<template>
    <div v-if="localProduct">
        <div class="product-form row">
            <div class="col-24">
                <div class="product-form__header d-flex align-items-center">
                    <div
                        :class="{ 'is-hidden': isHidden }"
                        @click.prevent="isHidden = !isHidden"
                    >
                        <icon
                            icon="plus"
                            v-if="!isHidden"
                            icon-classes="icon__plus--large icon--black icon--rotated"
                        />
                        <icon
                            icon="plus"
                            v-else
                            icon-classes="icon__plus--large icon--black"
                        />
                    </div>
                    <span class="product-form__header-title">{{ title }}</span>
                </div>
            </div>

            <transition name="transition-slide">
                <div
                    class="col-24"
                    v-if="!isHidden && connectedProducts.length > 0"
                >
                    <table class="items-table">
                        <thead>
                            <tr class="items-table__tr">
                                <th></th>
                                <th class="items-table__th">Index</th>
                                <th class="items-table__th">Nazwa</th>
                                <th class="items-table__th"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="items-table__tr"
                                v-for="product in connectedProducts"
                                :key="product.id"
                            >
                                <td
                                    class="items-table__td items-table__td--image"
                                >
                                    <div
                                        class="items-table__image items-table__image--small"
                                        :style="{
                                            backgroundImage:
                                                'url(' +
                                                getPhotoPath(product) +
                                                ')',
                                        }"
                                    ></div>
                                </td>
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">
                                        Index
                                    </div>
                                    {{ product.index }}
                                </td>
                                <td
                                    class="items-table__td items-table__td--expand"
                                >
                                    <div class="form-box__mobile-element">
                                        Nazwa
                                    </div>
                                    <router-link
                                        class="items-table__link"
                                        v-bind:to="{
                                            name: 'productEdit',
                                            params: { id: product.id },
                                        }"
                                        >{{ product.name }}
                                    </router-link>
                                </td>
                                <td
                                    class="items-table__td items-table__td--button"
                                >
                                    <div class="form-box__mobile-element">
                                        Akcje
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <button
                                            class="button-icon button-icon--ml-10"
                                            @click="
                                                connect(product.id, 'delete')
                                            "
                                        >
                                            <icon
                                                icon="unlink"
                                                icon-classes="icon--grey"
                                            />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="d-flex justify-content-end pt-4">
                        <button
                            class="button icon__icons-wrap button--grey"
                            @click="toggleModal()"
                        >
                            <icon
                                icon="link"
                                icon-classes="button__icon button__icon--left icon--regular"
                            />
                            <span>Powiąż</span>
                        </button>
                    </div>
                </div>
                <div
                    v-if="!connectedProducts.length > 0 && !isHidden"
                    class="col-24"
                >
                    <div class="d-flex justify-content-start">
                        <table class="items-table">
                            <tr class="items-table__tr">
                                <td class="items-table__td">
                                    <span class="items-table__noitems"
                                        >Brak pozycji.</span
                                    >
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="d-flex justify-content-end pt-4">
                        <button
                            class="button button--icon button--grey"
                            @click="toggleModal()"
                        >
                            <icon
                                icon="link"
                                icon-classes="button__icon button__icon--left icon--regular"
                            />
                            <span>Powiąż</span>
                        </button>
                    </div>
                </div>
            </transition>
        </div>

        <!-- Modal -->
        <modal :show="showModal" @close="showModal = !showModal">
            <div
                slot="header"
                class="d-flex flex-column flex-xl-row justify-content-xl-between align-items-xl-center w-100"
            >
                <div>{{ title }}</div>

                <div class="simple-search simple-search--modal pt-4">
                    <input
                        type="text"
                        class="simple-search__input"
                        @input="searchProducts()"
                        placeholder="Wyszukaj..."
                        v-model="search"
                    />

                    <treeselect
                        class="simple-search__input px-0 border-none rounded-0"
                        v-if="groups.length > 0"
                        v-model="group"
                        :multiple="false"
                        :options="groups"
                        :normalizer="normalizer"
                        placeholder="Grupa produktowa"
                        @input="searchProducts"
                        :clearable="false"
                    />
                    <button
                        class="simple-search__button"
                        @click="getProducts()"
                    >
                        <icon icon="search" />
                    </button>
                </div>
            </div>

            <div slot="body">
                <div v-if="!loadingProducts">
                    <button
                        class="button mb-4"
                        @click="bulkConnect"
                        :disabled="selected.length == 0"
                    >
                        Dodaj zaznaczone
                    </button>
                    <table
                        class="items-table"
                        v-if="products.data && products.data.length > 0"
                    >
                        <thead>
                            <tr class="items-table__tr !bg-red-200">
                                <th class="pr-4">
                                    <input
                                        class="checkbox"
                                        type="checkbox"
                                        @change="selectAll"
                                    />
                                </th>
                                <th></th>
                                <th class="items-table__th">Index</th>
                                <th
                                    class="items-table__th items-table__th--expand"
                                >
                                    Nazwa
                                </th>
                                <th class="items-table__th pr-0 text-right">
                                    Akcje
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="items-table__tr"
                                v-for="product in products.data"
                                :key="product.id"
                            >
                                <td>
                                    <input
                                        class="checkbox"
                                        type="checkbox"
                                        :disabled="
                                            connectedProductsIds.includes(
                                                product.id
                                            )
                                        "
                                        :value="product.id"
                                        v-model="selected"
                                        @change="
                                            (e) => addToSelected(e, product.id)
                                        "
                                    />
                                </td>
                                <td
                                    class="items-table__td items-table__td--image"
                                >
                                    <div
                                        class="items-table__image items-table__image--small"
                                        :style="{
                                            backgroundImage:
                                                'url(' +
                                                getPhotoPath(product) +
                                                ')',
                                        }"
                                    ></div>
                                </td>
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">
                                        Index
                                    </div>
                                    {{ product.index }}
                                </td>
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">
                                        Nazwa
                                    </div>
                                    {{ product.name }}
                                </td>
                                <td
                                    class="items-table__td items-table__td--button"
                                >
                                    <div class="form-box__mobile-element">
                                        Akcje
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <button
                                            class="button-icon"
                                            v-if="
                                                connectedProductsIds.includes(
                                                    product.id
                                                )
                                            "
                                            @click="
                                                connect(product.id, 'delete')
                                            "
                                        >
                                            <icon icon="unlink" />
                                        </button>
                                        <button
                                            class="button-icon"
                                            v-else
                                            @click="connect(product.id, 'add')"
                                        >
                                            <icon icon="link" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else>Brak produktów w bazie.</div>

                    <pagination
                        :pagination="products"
                        @paginate="getProducts()"
                        :offset="1"
                    ></pagination>
                </div>
                <div v-else class="d-flex align-items-center">
                    <div
                        class="loader button__icon button__icon--left d-inline-block"
                    ></div>
                    <div>Trwa wczytywanie produktów...</div>
                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button
                    class="button button--small button--grey"
                    @click="showModal = false"
                >
                    Anuluj
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
import Modal from "./../parts/Modal";
import axios from "axios";
import Treeselect from "@riophae/vue-treeselect";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const API_PHOTO_URL = process.env.VUE_APP_API_PHOTO_URL;
import _ from "lodash";
import Icon from "../parts/Icon";

import Pagination from "./../parts/Pagination";

export default {
    data() {
        return {
            isHidden: false,
            showModal: false,
            selected: [],
            products: {
                loading: true,
            },
            groups: [],
            group: null,
            loadingProducts: true,
            localProduct: false,
            connectedProducts: {},
            connectedProductsIds: [],
            search: "",
            order: "desc",
            orderBy: "created_at",
        };
    },

    props: {
        type: {
            type: String,
            default: "",
            required: true,
        },
        title: {
            type: String,
            default: "---",
        },
        product: {
            type: Object,
            required: true,
        },
    },
    watch: {
        product: {
            handler(newVal) {
                this.isLoaded = true;
                this.localProduct = _.cloneDeep(newVal);
                this.connectedProducts = this.localProduct[this.type];
                this.connectedProductsIds = [];
                this.connectedProducts.forEach((product) => {
                    this.connectedProductsIds.push(product.id);
                });
            },
            deep: true,
            immediate: false,
        },
    },
    components: {
        Modal,
        Pagination,
        Icon,
        Treeselect,
    },
    mounted() {
        this.getGroups();
    },
    methods: {
        addToSelected(e, id) {
            if (e.target.checked) {
                this.selected.push(id);
            }
        },
        connectAll() {},

        selectAll(e) {
            console.log(this.connectedProductsIds);
            if (e.target.checked) {
                this.selected = this.products.data
                    .filter(
                        (product) =>
                            this.connectedProductsIds.find(
                                (cp) => cp == product.id
                            ) === undefined
                    )
                    .map((product) => product.id);
            } else {
                this.selected = [];
            }
        },
        normalizer(node) {
            return {
                id: node.id,
                label: node.name,
            };
        },
        getGroups() {
            let vm = this;
            axios
                .get(API_BASE_URL + "/product-groups")
                .then((response) => {
                    vm.groups = response.data;
                })
                .catch((error) => {
                    vm.$root.$emit("noticesBox", error.response.data);
                });
        },
        async getProducts() {
            this.loadingProducts = true;
            this.selected = [];
            try {
                var params = {
                    page: this.products.current_page,
                    order_by: this.orderBy,
                    group_id: this.group,
                    order: this.order,
                    per_page: 10,
                    exclude: [this.$route.params.id],
                    lang: this.$store.state.language.current,
                };

                if (this.search != "") params.search = this.search;

                const response = await axios.get(API_BASE_URL + "/products", {
                    params: params,
                });
                this.products = response.data;
                this.loadingProducts = false;
            } catch (error) {
                this.loadingProducts = false;
                this.$root.$emit("noticesBox", error.response.data);
            }
        },
        searchProducts: _.debounce(function() {
            this.products.current_page = 1;
            this.getProducts();
        }, 400),
        toggleModal() {
            if (!this.products.length) {
                this.getProducts();
            }
            this.showModal = !this.showModal;
        },
        async connect(id, action = "add") {
            // TODO - zapis na przycisk, a nie po zmianie
            this.$emit("storingProduct", true);
            try {
                const response = await axios.post(
                    API_BASE_URL + "/products/connect",
                    {
                        product_id: this.$route.params.id,
                        product_connect_id: id,
                        relation_type: this.type,
                        action: action,
                    }
                );
                this.connectedProducts = response.data.product[this.type];
                this.connectedProductsIds = [];
                this.connectedProducts.forEach((product) => {
                    this.connectedProductsIds.push(product.id);
                });
                this.$emit("storingProduct", false);

                this.$root.$emit("noticesBox", {
                    type: "success",
                    message: response.data.message,
                });
            } catch (error) {
                this.$root.$emit("noticesBox", error.response.data);
                this.$emit("storingProduct", false);
            }
        },

        async bulkConnect() {
            // TODO - zapis na przycisk, a nie po zmianie
            this.$emit("storingProduct", true);
            try {
                const response = await axios.post(
                    API_BASE_URL + "/products/bulk-connect",
                    {
                        product_id: this.$route.params.id,
                        product_connect_ids: this.selected,
                        relation_type: this.type,
                        action: "add",
                    }
                );
                this.connectedProducts = response.data.product[this.type];
                this.connectedProductsIds = [];
                this.connectedProducts.forEach((product) => {
                    this.connectedProductsIds.push(product.id);
                });
                this.selected = [];
                this.$emit("storingProduct", false);

                this.$root.$emit("noticesBox", {
                    type: "success",
                    message: response.data.message,
                });
            } catch (error) {
                this.$root.$emit("noticesBox", error.response.data);
                this.$emit("storingProduct", false);
            }
        },
        getPhotoPath(product) {
            if (product.photos && product.photos[0] && product.photos[0].path) {
                return API_PHOTO_URL + "/" + product.photos[0].path;
            } else {
                return require("../../img/default.png");
            }
        },
    },
};
</script>
